// src/components/Sidebar.js
import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { Button, SidebarContainer, HamburgerButton } from '../styles';
import styled from 'styled-components';
import pb from '../pocketbase';

const Sidebar = ({ setActiveBoard }) => {
  const { boards, fetchBoards, pb } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);

  const treeData = boards.map((board) => ({
    key: board.id,
    title: board.name,
    children: [], // If you have nested boards or sub-items
  }));

  const onSelect = (selectedKeys) => {
    const selectedBoard = boards.find((board) => board.id === selectedKeys[0]);
    setActiveBoard(selectedBoard);
    setSelectedKey(selectedKeys[0]); // Track the selected item
  };

  const addBoard = async () => {
    const boardName = prompt('Enter board name:');
    if (boardName) {
      try {
        await pb.collection('boards').create({
          name: boardName,
          members: [pb.authStore.model.id],
        });
        await fetchBoards();
        setActiveBoard(boards[boards.length - 1]); // Set newly created board as active
      } catch (error) {
        console.error('Error adding board:', error);
      }
    }
  };

  return (
    <>
      <HamburgerButton onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? '☰' : '✕'}
      </HamburgerButton>
      <SidebarContainer collapsed={collapsed}>
        <h2>Boards</h2>
        <Tree
          treeData={treeData}
          onSelect={onSelect}
          selectedKeys={[selectedKey]} // Highlight the selected key
          showIcon={false}
          defaultExpandAll
         
        />
        <Button onClick={addBoard}>Add Board</Button>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;