// src/components/Header.js
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import styled from 'styled-components';
import { HeaderContainer,BoardTitle, UserInfo, LogoutButton, Avatar } from '../styles';

const Header = ({ activeBoard }) => {
  const { user, logout } = useContext(AuthContext);
  

  return (
    <HeaderContainer>
       <BoardTitle style={{'marginLeft': '50px'}}>{activeBoard ? activeBoard.name: "Select board"}</BoardTitle>
      
      <UserInfo>
        <span>{user?.email}</span>
        {user?.avatar && <Avatar src={user.avatar} alt="User Avatar" />}
        <LogoutButton onClick={logout}>Logout</LogoutButton>
      </UserInfo>
    </HeaderContainer>
  );
};

export default Header;