// src/components/KanbanBoard.js
import React, { useState, useEffect, useContext } from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { AuthContext } from '../context/AuthContext';
import KanbanHeader from './KanbanHeader';
import Column from './Column';
import { BoardContainer, DroppableContainer, BoardHeaderContainer } from '../styles'; // Import new styles
import pb from '../pocketbase';
import BoardSettingsModal from './BoardSettingsModal'; 

const KanbanBoard = ({ activeBoard }) => {
  const { fetchBoards } = useContext(AuthContext);
  const [tasks, setTasks] = useState([]);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const { logout } = useContext(AuthContext);
  const fetchTasks = async () => {
    if (!activeBoard) return;
    try {
      const result = await pb.collection('tasks').getFullList({
        filter: `board = "${activeBoard.id}"`,
        autoCancel: false,
      });
      setTasks(result);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const [columns, setColumns] = useState([]);

  const fetchColumns = async () => {
    if (!activeBoard) return;
    try {
      const result = await pb.collection('columns').getFullList({
        filter: `board = "${activeBoard.id}"`,
        sort: 'order',
        autoCancel: false,
      });
      setColumns(result);
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  };

  useEffect(() => {
    if (activeBoard) {
      fetchColumns();
      fetchTasks();
    }
  }, [activeBoard]);

  const onDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;

    if (type === 'column') {
      const newColumns = Array.from(columns);
      const [movedColumn] = newColumns.splice(source.index, 1);
      newColumns.splice(destination.index, 0, movedColumn);

      // Update column order in the backend
      for (let i = 0; i < newColumns.length; i++) {
        await pb.collection('columns').update(newColumns[i].id, { order: i });
      }

      setColumns(newColumns);
      return;
    }

    if (type === 'task') {
      const taskId = draggableId.replace('task-', '');
      const startColumnId = source.droppableId.replace('column-', '');
      const endColumnId = destination.droppableId.replace('column-', '');
  
      // Clone the tasks array to avoid mutating state directly
      let updatedTasks = [...tasks];
  
      // Find the moved task
      const movedTaskIndex = updatedTasks.findIndex((task) => task.id === taskId);
      const movedTask = updatedTasks[movedTaskIndex];
  
      if (!movedTask) return;
  
      // Update the task's column
      movedTask.column = endColumnId;
  
      // Remove the task from its original position
      updatedTasks.splice(movedTaskIndex, 1);
  
      // Insert the task into its new position
      const endColumnTasks = updatedTasks.filter((task) => task.column === endColumnId);
  
      // Sort the tasks in the end column by order
      endColumnTasks.sort((a, b) => a.order - b.order);
  
      // Insert the moved task at the destination index
      endColumnTasks.splice(destination.index, 0, movedTask);
  
      // Update the `order` values for tasks in the end column
      for (let i = 0; i < endColumnTasks.length; i++) {
        endColumnTasks[i].order = i;
        await pb.collection('tasks').update(endColumnTasks[i].id, {
          order: i,
          column: endColumnId,
        });
      }
  
      // Update the tasks in the state
      updatedTasks = [
        ...updatedTasks.filter((task) => task.column !== endColumnId),
        ...endColumnTasks,
      ];
  
      setTasks(updatedTasks);
    }
  };

  const addColumn = async () => {
    const columnName = prompt('Enter column name:');
    if (columnName) {
      try {
        await pb.collection('columns').create({
          name: columnName,
          order: columns.length,
          board: activeBoard.id,
        });
        fetchColumns();
      } catch (error) {
        console.error('Error adding column:', error);
      }
    }
  };

  const openSettings = () => setIsSettingsOpen(true);
  const closeSettings = () => setIsSettingsOpen(false);

  if (!activeBoard) {
    return <div>Loading board...</div>;
  }

  return (
    <>
      <BoardHeaderContainer>
        <KanbanHeader activeBoard={activeBoard} onAddColumn={addColumn} onOpenSettings={openSettings} />
        <DragDropContext onDragEnd={onDragEnd}>
        <BoardContainer>
          <Droppable droppableId="all-columns" direction="horizontal" type="column">
            {(provided) => (
              <DroppableContainer ref={provided.innerRef} {...provided.droppableProps}>
                {columns.map((column, index) => (
                  <Column
                    key={column.id}
                    column={column}
                    tasks={tasks
                      .filter((task) => task.column === column.id)
                      .sort((a, b) => a.order - b.order)}
                    fetchTasks={fetchTasks}
                    activeBoard={activeBoard}
                    index={index}
                  />
                ))}
                {provided.placeholder}
              </DroppableContainer>
            )}
          </Droppable>
        </BoardContainer>
      </DragDropContext>
        <BoardSettingsModal activeBoard={activeBoard} isOpen={isSettingsOpen} onClose={closeSettings} />
      </BoardHeaderContainer>
    </>
  );
};

export default KanbanBoard;