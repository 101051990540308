// src/styles.js
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
  background-color: #444e4eff;
  opacity: 1;
/*  background-image: url('snow.jpg');*/
  background-size: tile;
  background-position: center;
   /*background-color: #1e1e1e;  Dark background for the entire app */
    color: #ffffff;
    margin: 0;
    font-family: 'Arial', sans-serif;
  }

  h1, h2, h3 {
    margin: 2;
    font-weight: bold;
  }
    


  .rc-tree-treenode-selected .rc-tree-node-content-wrapper {
    background-color: transparent !important; /* Box effect for selected item */
    border-radius: 10px;
    padding: 15px 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    color: black !important; /* Text color for selected item */
  }

  .rc-tree-treenode-selected {
    background-color: #ffffff !important; /* Box effect for selected item */
    
    border-radius: 10px;
    padding: 15px 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    color: black !important; /* Text color for selected item */
  }

  .rc-tree-title {
    color: inherit; /* Inherit the color for regular unselected items */
    background-color: transparent; /* Ensure no background color for regular items */
  }

  .rc-tree-treenode {
    color: white; /* Ensure text remains black for all items */
  }


`;
export const BoardHeaderContainer = styled.div`
  
  flex-grow: 1;
  align-items: center;
  
  color: #ffffff;
  width: 100%!important; /* Ensure full width */
  height: 20%;
  box-sizing: border-box;
`;

export const BoardContainer = styled.div`
  display: flex;
  justify-content: center; 
  padding: 20px;
  
  box-sizing: border-box;
  width: 100%;
`;


export const ColumnContainer = styled.div`
  
  background-image: url('tilebackground.jpeg');
  background-size: cover;
  background-position: center;
  background-color: #333;
  min-height: 100vh;
  color: "#000000";
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  flex-grow: 1;
  flex-basis: 300px;
  //max-width: 400px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible; 
`;

export const DroppableContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-around; /* Distribute columns evenly */
  gap: 5px;
  flex-wrap: wrap; /* Allow wrapping of columns on smaller screens */
`;


export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  
  color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  
`;
export const BoardTitle = styled.h1`
  fontSize: 18px;
`;

export const SidebarContainer = styled.div`
  width: ${({ collapsed }) => (collapsed ? '60px' : '250px')};
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
  padding: 20px;
  color: #ffffff;
  border-right: 1px solid #333;
  transition: width 0.3s ease;
`;

export const TreeItem = styled.div`
  font-size: 16px;
  color: #ffffff;
`;

export const TreeItemSelected = styled.div`
  font-size: 16px;
  color: #ffffff;
  background-color: #444;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
`;
export const ColumnHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ColumnTitle = styled.h2`
  fontSize: 18px;
  margin-bottom: 15px;
  color: #ffffff;
`;
export const NewTaskForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px; // Adjust padding as needed
`;


export const TaskCardContainer = styled.div`
  background-color: #AAf7ec99;
  /*#f7ec99;*/
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  color: #333;
  font-family: 'Comic Sans MS', 'Chalkboard SE', sans-serif; /* Optional font for note style */
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2); /* Shadow for the sticky note effect */
  transform: rotate(-0deg); /* Slight tilt for the sticky note effect */
  transition: transform 0.1s ease;

  &:hover {
    transform: rotate(0deg) scale(1.05); /* Slight scaling on hover */
  }
    
`;
export const Pin = styled.div`
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: -5px;
  left: 0%;
  transform: translateX(-50%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

export const NewTaskContainer = styled.div`
  background-color: #AAAAAA;
`;

export const ThreeDotsButton = styled.button`
  background: none;
  border: none;
  color: #000000;
  cursor: pointer;
  fontSize: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  
  
  &:hover {
    color: #aaaaaa;
  }
`;

export const ContextMenu = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 30px;
  right: 10px;
  background-color: #444;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
 
`;

export  const TaskTitle = styled.h3`
  fontSize: 16px;
  margin-bottom: 5px;
  color: #000000;
`;
export const TaskDescription = styled.p`
  fontSize: 14px;
  color: #555555;
`;

export const Button = styled.button`
  background-color: #565656;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  margin: 2px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #676767;
  }
`;



export const ContextMenuItem = styled.div`
  padding: 10px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  &:hover {
    background-color: #555;
  }
`;

export const ModalOverlay = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2b2b2b;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  color: white;
  z-index: 1100;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
`;

export const BoardName = styled.h1`
  fontSize: 24px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  marginLeft: 10px;
`;

export const LogoutButton = styled.button`
  background-color: #444;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  marginLeft: 15px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #555;
  }
`;
export const Input = styled.input`
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #444;
  
  color: white;
`;
export const TextArea = styled.textarea`
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #444;
  color: white;
`;


export const HamburgerButton = styled.button`
  background-color: #333;
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
`;



export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const SmallButton = styled.button`
  background-color: #444;
  color: #ffffff;
  border: none;
  padding: 6px 10px;
  marginLeft: 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;