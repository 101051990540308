// src/App.js
import React, { useState, useContext } from 'react';
import { AuthContext } from './context/AuthContext';
import Login from './components/Login';
import Signup from './components/Signup';
import PasswordReset from './components/PasswordReset';
import KanbanBoard from './components/KanbanBoard';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { GlobalStyle,BoardContainer } from './styles';
import styled from 'styled-components';

const AppContainer = styled.div`
  display: flex;
`;


function App() {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState('login'); // Track which page to display
  const [activeBoard, setActiveBoard] = useState(null); // Track the active board
  const handleNavigate = (page) => setPage(page); // Handle page navigation

  if (!user) {
    if (page === 'login') {
      return <Login onNavigate={handleNavigate} />;
    } else if (page === 'signup') {
      return <Signup onNavigate={handleNavigate} />;
    } else if (page === 'resetPassword') {
      return <PasswordReset onNavigate={handleNavigate} />;
    }
  }

  // Show Kanban board if the user is logged in
  return (
  <>  <GlobalStyle />
  <Header activeBoard={activeBoard} />
  <AppContainer>
  
    <Sidebar setActiveBoard={setActiveBoard} />
    
    {activeBoard ? (
      <KanbanBoard activeBoard={activeBoard} />
    ) : (
      <div style={{ padding: '0px', color: '#ffffff' }}>Select a board from the sidebar.</div>
    )}
  </AppContainer>
  </>

  );
}

export default App;