// src/components/BoardSettings.js
import React, { useState } from 'react';
import pb from '../pocketbase';
import { Button } from '../styles';


const AddMemberToBoard = ({ activeBoard, fetchBoards }) => {
  const [email, setEmail] = useState('');

  const addMember = async (e) => {
    e.preventDefault();

    const recordList = await pb.collection('users').getFullList({
      filter: `email = "${email}"`,
      sort: '-created',
  });
    console.log(recordList);

    try {
      // Fetch the user by email
      
      if (recordList.length>0) {
        const userId = recordList[0].id;
        // Update the board's members
        const updatedMembers = [...activeBoard.members, userId];
  
        await pb.collection('boards').update(activeBoard.id, {
          members: updatedMembers,
        });
  
        // Refresh the list of boards
        fetchBoards();
  
        alert('Member added successfully!');
      } else {
        alert('User not found');
      }
    } catch (error) {
      console.log(recordList);
      alert('An error occurred while adding the member.');
    }
  
    setEmail('');
  };

  return (
    <div>
      <form onSubmit={addMember}>
        <input
          type="email"
          placeholder="Member's Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button type="submit">Add Member</Button>
      </form>
    </div>
  );
};

export default AddMemberToBoard;