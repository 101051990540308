// src/components/Column.js
import React, { useState, useEffect, useRef } from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import TaskCard from './TaskCard';
import NewTaskForm from './NewTaskForm';
import { Button, ColumnContainer, TaskCardContainer, ColumnHeader, ColumnTitle, ThreeDotsButton, ContextMenu, ContextMenuItem, Input } from '../styles';
import pb from '../pocketbase';

const Column = ({ column, tasks, fetchTasks, activeBoard, index }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [taskTitle, setTaskTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');

  const menuRef = useRef(null); // Reference for menu

  const handleAddTask = () => {
    setIsAddingTask(true); // Toggle the add task form
    setMenuOpen(false); // Close the menu
  };

  const handleAddTaskSubmit = async (e) => {
    e.preventDefault();
    try {
      await pb.collection('tasks').create({
        title: taskTitle,
        description: taskDescription,
        board: activeBoard.id,
        user: pb.authStore.model.id,
        column: column.id,
      });
      setTaskTitle('');
      setTaskDescription('');
      setIsAddingTask(false); // Close the add task form
      fetchTasks(); // Refresh the task list
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleRename = async () => {
    const newName = prompt('Enter new column name:', column.name);
    if (newName) {
      try {
        await pb.collection('columns').update(column.id, { name: newName });
        fetchTasks(); // Refresh the columns
      } catch (error) {
        console.error('Error renaming column:', error);
      }
    }
    setMenuOpen(false);
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this column?');
    if (confirmDelete) {
      try {
        await pb.collection('columns').delete(column.id);
        fetchTasks(); // Refresh the columns after deletion
      } catch (error) {
        console.error('Error deleting column:', error);
      }
    }
    setMenuOpen(false);
  };


  const closeTaskForm = () => {
    setIsAddingTask(false); // Close the form when task is saved or canceled
  };
  return (
    <Draggable draggableId={`column-${column.id}`} index={index}>
      {(provided) => (
        <ColumnContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={provided.draggableProps.style}
        >
          <ColumnHeader {...provided.dragHandleProps}>
            <ColumnTitle>{column.name}</ColumnTitle>
            <ThreeDotsButton onClick={toggleMenu}>⋮</ThreeDotsButton>
          </ColumnHeader>

          {/* Context Menu */}
          <ContextMenu ref={menuRef} isOpen={menuOpen}>
            <ContextMenuItem onClick={handleAddTask}>Add Task</ContextMenuItem>
            <ContextMenuItem onClick={handleRename}>Rename Column</ContextMenuItem>
            <ContextMenuItem onClick={handleDelete}>Delete Column</ContextMenuItem>
          </ContextMenu>

         
          <Droppable droppableId={`column-${column.id}`} type="task">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {tasks.map((task, index) => {
                  return (
                    <Draggable
                      key={`task-${task.id}`}
                      draggableId={`task-${task.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            margin: 0, // Ensure no margins
                            boxShadow: snapshot.isDragging
                              ? '0 2px 8px rgba(0, 0, 0, 0.5)'
                              : 'none',
                          }}
                        >
                          <TaskCard task={task} index={index} fetchTasks={fetchTasks} />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          {/* Add Task Form */}
          {isAddingTask && (
            <NewTaskForm column= {column} fetchTasks={fetchTasks} activeBoard={activeBoard} closeFormCb={closeTaskForm}/>
          )}
          {!isAddingTask && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <Button onClick={handleAddTask} style={{ 'fontSize': '20px',backgroundColor: '#222222', width: '40px' }}>
              +
            </Button>
          </div>
          )}
        </ColumnContainer>
      )}
    </Draggable>
  );
};

export default Column;