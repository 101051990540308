// src/context/AuthContext.js

import React, { createContext, useEffect, useState, useCallback } from 'react';
import pb from '../pocketbase';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(pb.authStore.model);
  const [boards, setBoards] = useState([]);

  useEffect(() => {
    const authChangeUnsubscribe = pb.authStore.onChange(() => {
      setUser(pb.authStore.model);
    });
    return () => {
      authChangeUnsubscribe();
    };
  }, []);

  const fetchBoards = useCallback(async () => {
    if (user) {
      try {
        const userBoards = await pb.collection('boards').getFullList({
          filter: `members.id ?= "${user.id}"`,
          expand: 'members',
          autoCancel: false, // Add this line
        });
        setBoards(userBoards);
      } catch (error) {
        console.error('Error fetching boards:', error);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchBoards();
  }, [user, fetchBoards]);

  const logout = () => {
    pb.authStore.clear();
    setUser(null);
  };


  return (
    <AuthContext.Provider value={{ user, pb, boards, fetchBoards, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;