// src/components/Signup.js
import React, { useState } from 'react';
import styled from 'styled-components';
import pb from '../pocketbase';

const SignupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #2e2e2e;
`;

const SignupForm = styled.form`
  background-color: #333;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
`;

const FormTitle = styled.h2`
  color: #ffffff;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #555;
  color: #ffffff;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4e4e4e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #5e5e5e;
  }
`;

const LinkButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const Signup = ({ onNavigate }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await pb.collection('users').create({
        email: email,
        password: password,
        passwordConfirm: password,
        emailVisibility:true,
      });
      window.location.reload(); // Refresh after signup
    } catch (err) {
      setError('Error signing up. Please try again.');
    }
  };

  return (
    <SignupContainer>
      <SignupForm onSubmit={handleSignup}>
        <FormTitle>Sign Up</FormTitle>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button type="submit">Sign Up</Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <LinkButton onClick={() => onNavigate('login')}>Back to Login</LinkButton>
      </SignupForm>
    </SignupContainer>
  );
};

export default Signup;