// src/components/Login.js
import React, { useState } from 'react';
import styled from 'styled-components';
import pb from '../pocketbase'; // PocketBase client instance
import { Input,Button } from '../styles';
const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  /* Full screen height */
  background-color: #2e2e2e;
  background-image: url('tilebackground.jpg');
  background-size: tile;
  background-position: center;
`;

const LoginForm = styled.form`
  background-color: #444444;
  
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
`;

const FormTitle = styled.h2`
  color: #ffffff;
  margin-bottom: 20px;
`;



const LinkButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const Login = ({ onNavigate }) => {
  const [identifier, setIdentifier] = useState(''); // Can be email or username
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Authenticate using email/username and password
      await pb.collection('users').authWithPassword(identifier, password);
      window.location.reload(); // Refresh after login
    } catch (err) {
      setError('Invalid email/username or password.');
    }
  };

  return (
    <LoginContainer>
      <LoginForm onSubmit={handleLogin}>
        <FormTitle>MakitDone</FormTitle>
        <Input
          type="text"
          placeholder="Username or Email"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          style={{"width": "80%"}}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{"width": "80%"}}
          required
        />
        <Button type="submit">Login</Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <LinkButton onClick={() => onNavigate('signup')}>Sign Up</LinkButton>
        <LinkButton onClick={() => onNavigate('resetPassword')}>Forgot Password?</LinkButton>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;