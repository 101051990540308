// src/components/TaskCard.js
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Draggable } from '@hello-pangea/dnd';
import pb from '../pocketbase';
import { TaskCardContainer, NewTaskContainer, Pin, ThreeDotsButton,TaskDescription,TaskTitle, ContextMenu, ContextMenuItem,Button } from '../styles';


const TaskCard = ({ task, index, fetchTasks }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(task.title);
  const [description, setDescription] = useState(task.description);
  const menuRef = useRef(null); // Reference for menu
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleDelete = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this task?');
    if (confirmDelete) {
      try {
        await pb.collection('tasks').delete(task.id);
        fetchTasks(); // Refresh tasks after delete
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    }
    setMenuOpen(false);
  };

  const handleEdit = () => {
    setIsEditing(true); // Toggle editing mode
    setMenuOpen(false); // Close the menu
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    try {
      await pb.collection('tasks').update(task.id, {
        title: title,
        description: description.trim() || "", // Optional field
      });
      fetchTasks(); // Refresh tasks after edit
      setIsEditing(false); // Close the edit mode
    } catch (error) {
      console.error('Error editing task:', error);
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <TaskCardContainer><Pin></Pin>
      {isEditing ? (
        
        <form onSubmit={handleSaveEdit}>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ width: '95%', padding: '5px', marginBottom: '5px' }}
          required // Only title is required
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ width: '95%', padding: '5px' }}
          placeholder="Add description (optional)" // Optional field
        />
        <Button type="submit" style={{ marginTop: '10px' }}>
          Save
        </Button>
        <Button onClick={() => setIsEditing(false)} style={{ marginTop: '10px' }} >
          Cancel
        </Button>
      </form>
      ) : (
        <>
          <TaskTitle>{task.title}</TaskTitle>
          <TaskDescription>{task.description}</TaskDescription>
          <ThreeDotsButton onClick={toggleMenu}>⋮</ThreeDotsButton>

          {/* Context Menu */}
          <ContextMenu isOpen={menuOpen}>
            <ContextMenuItem onClick={handleEdit}>Edit Task</ContextMenuItem>
            <ContextMenuItem onClick={handleDelete}>Delete Task</ContextMenuItem>
          </ContextMenu>
        </>
      )}
    </TaskCardContainer>
  );
};

export default TaskCard;