// src/components/BoardSettingsModal.js
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import AddMemberToBoard from './AddMemberToBoard';
import { AuthContext } from '../context/AuthContext';

const ModalOverlay = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  color: white;
  z-index: 1100;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  background-color: #444;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;

const BoardSettingsModal = ({ activeBoard, isOpen, onClose }) => {
    const { fetchBoards } = useContext(AuthContext);
  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContainer>
        <ModalHeader>
          <h2>Board Settings</h2>
          <CloseButton onClick={onClose}>Close</CloseButton>
        </ModalHeader>
        <AddMemberToBoard activeBoard={activeBoard} fetchBoards={fetchBoards} />
        {/* Add more settings here later */}
      </ModalContainer>
    </ModalOverlay>
  );
};

export default BoardSettingsModal;