import React, { useState } from 'react';
import pb from '../pocketbase';
import { Button, Pin, TaskCardContainer } from '../styles';
const NewTaskForm = ({ column, fetchTasks, activeBoard, closeFormCb }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');


  const createTask = async (e) => {
    e.preventDefault();
    try {
      await pb.collection('tasks').create({
        title,
        description,
        board: activeBoard.id,
        user: pb.authStore.model.id,
        column: column.id
      });
      setTitle('');
      setDescription('');
      fetchTasks();
      closeFormCb();
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };
  const cancelAction = () => {
   
    setTitle('');
    setDescription('');
    closeFormCb();
  };

  return (
    <TaskCardContainer><Pin></Pin>
    <form onSubmit={createTask}>
      <input
        type="text"
        placeholder="Title"
        style={{ width: '95%', padding: '5px' }}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{ width: '95%', padding: '5px' }}
        placeholder="Add description (optional)" // Optional field
      />
     <Button type="submit" style={{ marginTop: '10px' }}>
          Save
        </Button>
        <Button onClick={() => cancelAction()} style={{ marginTop: '10px' }} >
          Cancel
        </Button>
    </form>
    </TaskCardContainer>
  );
};

export default NewTaskForm;