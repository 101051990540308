// src/components/PasswordReset.js
import React, { useState } from 'react';
import styled from 'styled-components';
import pb from '../pocketbase';

const ResetContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #2e2e2e;
`;

const ResetForm = styled.form`
  background-color: #333;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
`;

const FormTitle = styled.h2`
  color: #ffffff;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #555;
  color: #ffffff;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4e4e4e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #5e5e5e;
  }
`;

const LinkButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: green;
  margin-top: 10px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const PasswordReset = ({ onNavigate }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await pb.collection('users').requestPasswordReset(email);
      setMessage('Password reset email sent.');
      setError(null);
    } catch (err) {
      setError('Error sending password reset email.');
      setMessage(null);
    }
  };

  return (
    <ResetContainer>
      <ResetForm onSubmit={handlePasswordReset}>
        <FormTitle>Reset Password</FormTitle>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button type="submit">Send Reset Email</Button>
        {message && <SuccessMessage>{message}</SuccessMessage>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <LinkButton onClick={() => onNavigate('login')}>Back to Login</LinkButton>
      </ResetForm>
    </ResetContainer>
  );
};

export default PasswordReset;