// src/components/KanbanHeader.js
import React from 'react';
import styled from 'styled-components';

import { HeaderContainer, ButtonGroup, SmallButton} from '../styles';




const KanbanHeader = ({ activeBoard, onAddColumn, onOpenSettings }) => {
 

  return (
    <HeaderContainer>
      <ButtonGroup>
        <SmallButton onClick={onAddColumn}>Add Column</SmallButton>
        <SmallButton onClick={onOpenSettings}>Board Settings</SmallButton>
      </ButtonGroup>
    </HeaderContainer>
  );
};

export default KanbanHeader;